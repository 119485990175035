import Vue from "vue";
import Router from "vue-router";
import indexDetail from "@/components/indexDetail.vue";
import bindUser from "@/views/bindUser.vue";
import contactIndex from "@/views/uploadIndex.vue";
import myIndex from "@/views/person/myIndex.vue";
import home from "@/views/home.vue";
import welcome from "@/views/welcome.vue";
import indexView from "@/views/indexView.vue";
import listIndex from "@/views/listIndex.vue";
import conversionRate from "@/views/person/conversionRate.vue";
import api from "@/api/api.js";
import cookies from "vue-cookies";
//import data from "./store/data";
import request from "@/api/request.js";
import store from "@/store/store.js";
Vue.use(Router);
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject)
//   }

// }

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      //redirect: '/uploadIndex'
      component: home,
      //children: [{}]
    },
    {
      path: "/welcome",
      name: "welcome",
      component: welcome,
    },
    {
      path: "/indexView/:id",
      name: "indexView",
      component: indexView,
    },
    {
      path: "/listIndex",
      name: "listIndex",
      component: listIndex,
    },
    {
      path: "/bindUser/:useraccount",
      name: "bindUser",
      component: bindUser,
    },
    {
      path: "/uploadIndex",
      name: "uploadIndex",
      component: contactIndex,
    },
    {
      path: "/indexDetail/:id",
      name: "indexDetail",
      component: indexDetail,
    },
    {
      path: "/person/myIndex",
      name: "myIndex",
      component: myIndex,
    },
    {
      path: "/person/conversionRate",
      name: "conversionRate",
      component: conversionRate,
    },
  ],
});

router.beforeEach((to, _from, next) => {
  //页面是否登录
  const queryStr = window.location.search;
  const urlParams = new URLSearchParams(queryStr);
  let token = cookies.get("token")?cookies.get("token"):urlParams.get("token");
  let marketId = urlParams.get("marketId");
  console.log(token+'/'+marketId);
  if(marketId) sessionStorage.setItem("market_id",marketId);
  let targetUrl = window.location.href;
  //已经绑定登录过流程判断
  if (token) {
    if (store.state.user == null) {
      cookies.set("token", token, 60 * 60 * 24 * 7);
      init().then((res) => {
        next();
      });
    } else {
      next();
    }
  } else {
    let url = api.getBaseUrl + "/oauth/wxOauth?targetUrl=" + targetUrl;
    request({
      url: url,
      method: "get",
    })
      .then((res) => {
        window.location.href=res.data.url;
      });
  }

  // window.__wxjs_is_wkwebview
  // true 时 为 IOS 设备
  // false时 为 安卓 设备
  if (window.__wxjs_is_wkwebview) {
    // IOS
    if (window.entryUrl == "" || window.entryUrl == undefined) {
      var url = `${to.fullPath}`;
      window.entryUrl = url; // 将后面的参数去除
    }
    console.log("ios/" + to.fullPath);
    //getWxAuth(to.fullPath,"ios")
  } else {
    // 安卓
    setTimeout(function () {
      //getWxAuth(to.fullPath,"android")
    }, 500);
  }
});

function init() {
  return request({
    url: "/login/getUser",
    method: "get",
  })
    .then((res) => {
      store.commit("userInfo", res.data.user);
      return request({
        url: "/market/getMarketAllForAccount",
        method: "get",
        params: { userAccount: res.data.user.useraccount },
      });
    })
    .then(
      (res) => {
        if (res.data.marketList.length > 0) {
          if(!sessionStorage.getItem("market_id")) sessionStorage.setItem("market_id", res.data.marketList[0].id);
        }
        store.commit("userMarketList", res.data.marketList);
      },
      (rej) => {}
    );
}
export default router;
